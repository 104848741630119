import './assets/style/reset.css'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopBar from "./components/TopBar";
import HomeCom from "./components/HomeCom"
import BottomBar from "./components/BottomBar";
import WhiteBook from "./components/WhiteBook";
import NoKyc from "./components/NoKyc";
import OperationWay from "./components/OperationWay";
import PledgeCoin from "./components/PledgeCoin";
import WeGame from "./components/WeGame";
import PreSale from "./components/PreSale";
import CryptoNews from "./components/CryptoNews";
import CoinChance from "./components/CoinChance";
import CoinTimes from "./components/CoinTimes";
import EconomicToken from "./components/EconomicToken";
import SafeInstructions from "./components/SafeInstructions";
import LicenseProof from "./components/LicenseProof";
import WhyPopular from "./components/WhyPopular";
import CommonProblem from "./components/CommonProblem";
import HowToBuy from "./components/HowToBuy";
import PledgeDetail from "./components/PledgeDetail"
import LicensePage from "./components/LicensePage";
import {db} from './util/contactUtil/indexdb';

const App = () => {
    const dispatch = useDispatch();
    const toggleHowToBuy: any = useSelector((state: any) => state.global.toggleHowToBuy);
    const togglePledgeDetail: any = useSelector((state: any) => state.global.togglePledgeDetail);
    const toggleLicensePage: any = useSelector((state: any) => state.global.toggleLicensePage);

    useEffect(() => {
        const PledgeDetail = sessionStorage.getItem("togglePledgeDetail")
        const HowToBuy = sessionStorage.getItem("toggleHowToBuy")
        const LicensePage = sessionStorage.getItem("toggleLicensePage")
        dispatch({ type: 'setTogglePledgeDetail', data: PledgeDetail === "true" })
        dispatch({ type: 'setToggleHowToBuy', data: HowToBuy === "true" })
        dispatch({ type: 'setLicensePage', data: LicensePage === "true" })
        try {
            const verifyData = async () => {
                await db.init();
                await db.checkNamespace();
            };
    
            verifyData();
        } catch (error) {
            
        }

    }, []);
    return (<div className="home">
        <TopBar />
        {
            togglePledgeDetail ? <PledgeDetail /> : ""
        }
        {
            toggleHowToBuy ? <HowToBuy /> : ""
        }
        {
            toggleLicensePage ? <LicensePage /> : ""
        }
        {
            (!toggleHowToBuy && !togglePledgeDetail && !toggleLicensePage) ? <div style={{ overflow: "hiden" }}>
                <HomeCom />
                <CryptoNews />
                <PreSale />
                <OperationWay />
                <PledgeCoin />
                <EconomicToken />
                <SafeInstructions />
                <CoinChance />
                <CoinTimes />
                <WhiteBook />
                <WeGame />
                <WhyPopular />
                <NoKyc />
                <LicenseProof />
                <CommonProblem />
            </div> : ""
        }
        <BottomBar />
    </div>);
};

export default App;
