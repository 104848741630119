import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import './index.css';
import './util/lang/config';
import store from './redux/store'
import { Buffer } from 'buffer';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  rainbowWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { imTokenWallet } from '@rainbow-me/rainbowkit/wallets';


import {
  mainnet,
  sepolia
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import App from './App';
import { projectId } from './util/const/const';
import { CountlyService } from './util/helper/countlyUtil';
//Exposing Countly to the DOM as a global variable
//Usecase - Heatmaps


CountlyService.getInstance()

// const appChain  = (process.env.REACT_APP_ENV === 'production') ? mainnet : sepolia

const appChain = mainnet

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    appChain,
  ],
  [publicProvider()]
);

const { wallets } = getDefaultWallets({
  appName: 'TG.Bet',
  projectId: projectId,
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: 'Other',
    wallets: [
      imTokenWallet({ projectId, chains }),
    ],
  },
]);


const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.Buffer = window.Buffer || Buffer;

root.render(
    <Provider store={store}>
      {/* <React.StrictMode> */}
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider chains={chains}>
            <App />
          </RainbowKitProvider>
        </WagmiConfig>
      {/* </React.StrictMode> */}
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
