import Cookies from 'js-cookie'
import Countly from 'countly-sdk-web';

import { Countlykeys } from '../const/countlyKey';

const filterKeys = [
  Countlykeys.stake_click,
  Countlykeys.unstake_click,
  Countlykeys.audit_click,
  Countlykeys.twitter_click,
  Countlykeys.tg_click,
  Countlykeys.gameTg_click,
  Countlykeys.tgDownload_click,
  Countlykeys.whitePaper_click,
  Countlykeys.license_click
]

const CountlyService = (() => {
  let instance = null;

  function createInstance() {
    try {
      let countly = Countly.init({
        app_key: 'fac573e2a0ac85f7410df4c5037fb4e699d5350d',
        url: 'https://analyze.countly.io/',
        app_version: "1.0.0",
        debug: false,
        remote_config: true
      });

      // inviteCode
      let appKey = Cookies.get('countly.app.key');

      if (appKey) {
        let appid = Cookies.get('countly.app.id');
        if (appid) {
          localStorage.setItem("inviteCode", appid);
        }
        let eventKey = Cookies.get('countly.app.name');
        if (eventKey) {
          localStorage.setItem("eventKey", atob(eventKey));
        }
        let tgid = Cookies.get('countly.app.info');
        if (tgid) {
          localStorage.setItem("tgid", tgid);
        }
        // initialize second instance for another app 
        Countly.q.push(["init", {
          app_key: appKey, //must have different APP key
          url: 'https://analyze.countly.io/' //your server goes here
        }]);
        // Track sessions automatically (recommended)
        Countly.q.push([appKey, 'track_sessions']);

        // Track web page views automatically (recommended)
        Countly.q.push([appKey, 'track_pageview']);
      }


      return countly;
    } catch (error) {
      return instance
    }

  }

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

const getInviteCode = () => {
  return localStorage.getItem("inviteCode") || ''
}

//添加埋点
const addEvent = (eventName, amount) => {
  Countly.q.push(['add_event', {
    "key": eventName,
    "count": 1,
    "sum": amount || 1,
    "segmentation": {
      "channel": localStorage.getItem("eventKey") || ''
    }
  }])

  if (filterKeys.includes(eventName)) {

    return
  }

  let appKey = Cookies.get('countly.app.key');
  if (appKey) {
    Countly.q.push([appKey, "add_event", {
      "key": eventName,
      "count": 1,
      "sum": amount || 1
    }]);
  }
}

const requestCountlyConfig = () => {
  return new Promise(resovle => {
    Countly.fetch_remote_config(function (err, remoteConfigs) {
      if (!err) {
        if (remoteConfigs) {
          let soldUsd = remoteConfigs.sold.totalSaleUSD;
          let result = {}

          if (soldUsd || soldUsd === 0) {
            localStorage.setItem('soldUsd', soldUsd)
            result.soldUsd = soldUsd;
          }
          let line = remoteConfigs.line
          if (line) {
            localStorage.setItem('line', JSON.stringify(line))
            result.line = line;
          }
          return resovle(result);
        }
      } else {
        Countly.q.push(['log_error', {
          message: err.message,
          stack: err.stack
        }]);
      }
      return resovle({});
    });
  })
};

//更新用户资料
const updateUserDetail = (address) => {

  if (!address) {

    return
  }
  let inviteCode = getInviteCode()
  if (inviteCode !== '0') {
    Countly.q.push(['user_details', {
      username: "IC" + inviteCode,
      "custom": {
        "address": address.toLowerCase(),
        "channel": inviteCode
      }
    }]);
  } else {
    Countly.q.push(['user_details', {
      "custom": {
        "address": address.toLowerCase(),
      }
    }]);
  }

}


const reportError = (errorMsg) => {
  try {
    if (!errorMsg) return

    Countly.q.push(['log_error', {
      message: errorMsg,
    }]);
  } catch (error) {

  }
}


const catchError = (error) => {
  try {
    const metamaskError = error?.message?.split(".")[0] || "Unknown error";
    const message = error?.message ? metamaskError : error;
    Countly.q.push(['log_error', {
      message: message,
    }]);
  } catch (err) {

  }
  return true;
}

export { CountlyService, addEvent, updateUserDetail, requestCountlyConfig, catchError, reportError }

