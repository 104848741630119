import Dexie, { Table } from 'dexie';


class Db {

    constructor(dbName) {
        this.dbName = dbName;
    }

    async init() {
        try {
            const exists = await Dexie.exists(this.dbName);
            if (exists) {
                this.db = new Dexie(this.dbName);
                await this.db.open(); 
                const keyvaluestorage = this.db.table('keyvaluestorage');
                if (keyvaluestorage.schema.idxByName.key) {
                    Dexie.delete(this.dbName);
                }
                return;
            }
        } catch (error) {
            console.log(error)
        }
    }

    async checkNamespace() {
        try {
            if (!this.db) { return null }
            const namespaces = await this.db.table('keyvaluestorage').get("wc@2:universal_provider:namespaces");
            if (namespaces == '{}') {
                await this.db.table('keyvaluestorage').bulkDelete(["wc@2:universal_provider:namespaces"])
            }
            return namespaces;
        } catch (error) {
            console.log(error)
            return null
        }
    }
}

export const db = new Db('WALLET_CONNECT_V2_INDEXED_DB');