import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PledgeDetailArea from './PledgeDetailArea'
import { Row, Col, Input, message } from 'antd'
import '../assets/style/PledgeDetailArea.scss'
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { addEvent, catchError,reportError } from '../util/helper/countlyUtil';
import { Countlykeys } from '../util/const/countlyKey';
import { checkApprove, approveContract } from '../util/contactUtil/approve';
import { waitTx } from '../util/contactUtil/transfaction';
import { formatUnits, parseEther } from 'viem'
import {
    getNetwork,
    switchNetwork,
    fetchBalance
} from "@wagmi/core";

import {

    useAccount
} from 'wagmi'
import {
    tgbAddress,
    getTgbContract,
    getPreSaleContract,
    getStakeContract
} from '../util/const/const'
import { appPublicClient, appWallectClient,appChain } from '../util/contactUtil/client';
// eslint-disable-next-line
export default () => {

    const { t } = useTranslation();
    const [infoData, setInfoData] = useState({} as any);
    let [myRewardAmount, setMyRewardAmount] = useState({} as any)
    let [stakeAmount, setStakeAmount] = useState({} as any)
    let [unStakeAmount, setUnStakeAmount] = useState({} as any)

    const [processing, setProcessing] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();
    // const { disconnect } = useDisconnect()
    const { openConnectModal } = useConnectModal();

    const [connected, setConnected] = useState(false)

    const account = useAccount({
        onConnect({ address, connector, isReconnected }) {
            //console.log('Connected', { address, connector, isReconnected })
            setConnected(true)
            stakeInfo()
        },
        onDisconnect() {
            //console.log('Disconnected')
            setConnected(false)
        },
    })
    const connectWithWalletConnect = () => {
        openConnectModal && openConnectModal()
    }
    const getMyStakeReward = async () => {
        try {
            if (connected) {
                let netResult = false
                netResult = await checkNetwork()
                if (!netResult) { return }
            }
            let stakeContract = getStakeContract()
            let amount = myRewardAmount

            if (!amount || amount < 100) {
                messageApi.open({ type: 'warning', content: t('tip.text14'), });
                return
            }

            amount = Math.floor(amount).toFixed(0)
            addEvent(Countlykeys.stakeReward_click, parseInt(amount))

            setProcessing(true)
            let wallectClient = await appWallectClient()
            // @ts-ignore
            let hash = await wallectClient.writeContract({
                ...stakeContract,
                functionName: "harvestRewards",
                account: account.address
            })
            //console.log('getMyStakeReward tx hash' + hash)
            if (hash) {
                messageApi.open({ type: 'success', content: t('tip.text11'), });
                let result = await waitTx(hash)
                if (result) {
                    messageApi.open({ type: 'success', content: t('tip.text12'), });
                } else {
                    messageApi.open({ type: 'error', content: t('tip.text13'), });
                }
            } else {
                messageApi.open({ type: 'error', content: t('tip.text13'), });
            }
            setProcessing(false)
            stakeInfo()
        } catch (err) {
            setProcessing(false)
            console.log(`originErr: err ${err} json err:${JSON.stringify(err)}  `)
            // @ts-ignore
            if (err && err.shortMessage) {
                // @ts-ignore
                if (err.shortMessage == 'User rejected the request.') {
                    messageApi.open({ type: 'error', content: 'User rejected the request.', });
                } else {
                    messageApi.open({ type: 'error', content: 'An unknown RPC error occurred', });
                }
            }
            throw err
        }
    }
    const stakeToken = async () => {
        try {
            if (connected) {
                let netResult = false
                netResult = await checkNetwork()
                if (!netResult) { return }
            }
            if (!stakeAmount || stakeAmount < 100) {
                messageApi.open({ type: 'warning', content: t('tip.text15'), });
                return
            }
            addEvent(Countlykeys.stake_click, parseInt(stakeAmount))

            let stakeContract = getStakeContract()
            let tgbContract = getTgbContract()

            setProcessing(true)

            let allowanceData = await checkApprove(tgbContract, account.address, stakeContract.address)

            let amount = parseEther((stakeAmount).toString())

            // @ts-ignore
            if (BigInt(allowanceData) < amount) {
                messageApi.open({ type: 'warning', content: t('tip.text21'), });

                let approveTx = await approveContract(tgbContract, stakeContract.address, account)
                if (approveTx) {
                    messageApi.open({ type: 'success', content: t('tip.text27'), });
                    let result = await waitTx(approveTx)
                    if (result) {
                        messageApi.open({ type: 'success', content: t('tip.text28'), });
                    } else {
                        messageApi.open({ type: 'error', content: t('tip.text29'), });
                    }
                }
                setProcessing(false)
                return
            }

            let wallectClient = await appWallectClient()

            // @ts-ignore
            let hash = await wallectClient.writeContract({
                ...stakeContract,
                functionName: "deposit",
                args: [amount],
                account: account.address
            })
            //console.log('stakeToken tx hash' + hash)
            if (hash) {
                messageApi.open({ type: 'success', content: t('tip.text11'), });
                let result = await waitTx(hash)
                if (result) {
                    messageApi.open({ type: 'success', content: t('tip.text12'), });
                } else {
                    messageApi.open({ type: 'error', content: t('tip.text13'), });
                }
            } else {
                messageApi.open({ type: 'error', content: t('tip.text13'), });
            }
            setProcessing(false)
            stakeInfo()
        } catch (err) {
            setProcessing(false)
            //console.log(`originErr: err ${err} json err:${JSON.stringify(err)}  `)
            // @ts-ignore
            if (err && err.shortMessage) {
                // @ts-ignore
                if (err.shortMessage == 'User rejected the request.') {
                    messageApi.open({ type: 'error', content: 'User rejected the request.', });
                } else {
                    messageApi.open({ type: 'error', content: 'An unknown RPC error occurred', });
                }
            }
            throw err
        }
    }
    const unStakeToken = async () => {
        try {
            if (connected) {
                let netResult = false
                netResult = await checkNetwork()
                if (!netResult) { return }
            }
            let diffDays = Math.floor((infoData.endTime * 1000 - new Date().getTime()) / 1000 / 24 / 3600)

            if (diffDays) {
                messageApi.open({ type: 'error', content: t('tip.text17'), });
                return
            }
            addEvent(Countlykeys.unstake_click, parseInt(unStakeAmount))

            let amount = parseEther(unStakeAmount)

            if (!amount || amount < 100) {
                messageApi.open({ type: 'error', content: t('tip.text16'), });
                return
            }

            let stakeContract = getStakeContract()

            amount = BigInt(Math.floor(Number(amount)).toFixed(0))
            setProcessing(true)

            let wallectClient = await appWallectClient()

            // @ts-ignore
            let hash = await wallectClient?.writeContract({
                ...stakeContract,
                functionName: "withdraw",
                args: [amount],
                account: account.address
            })
            //('unStakeToken tx hash' + hash)
            if (hash) {
                messageApi.open({ type: 'success', content: t('tip.text11'), });
                let result = await waitTx(hash)
                if (result) {
                    messageApi.open({ type: 'success', content: t('tip.text12'), });
                } else {
                    messageApi.open({ type: 'error', content: t('tip.text13'), });
                }
            } else {
                messageApi.open({ type: 'error', content: t('tip.text13'), });
            }
            setProcessing(false)
            stakeInfo()
            setProcessing(false)
            stakeInfo()
        } catch (err) {
            setProcessing(false)
            //console.log(`originErr: err ${err} json err:${JSON.stringify(err)}  `)
            // @ts-ignore
            if (err && err.shortMessage) {
                // @ts-ignore
                if (err.shortMessage == 'User rejected the request.') {
                    messageApi.open({ type: 'error', content: 'User rejected the request.', });
                } else {
                    messageApi.open({ type: 'error', content: 'An unknown RPC error occurred', });
                }
            }
            throw err
        }
    }
    const checkNetwork = async () => {
        try {
            const currentNetwork = getNetwork()

            if (!currentNetwork || !currentNetwork.chain) {
                reportError('currentNetwork fetch error')
                return false;
            }
            let currentNetId = currentNetwork.chain.id

            if (currentNetId !== appChain.id) {
                const network = await switchNetwork({
                    chainId: appChain.id,
                })
                if (network.id === appChain.id) {
                    return true
                } else {
                    return false
                }
            }
            return true
        } catch (error) {
            catchError(error) 
            throw error
        }
    }

    const stakeInfo = async () => {
        try {
            if (connected) {
                let netResult = false
                netResult = await checkNetwork()
                if (!netResult) { return }
            }
            let myAddress = ""
            if (account && account.address) {
                myAddress = account.address
            }
            else {
                return
            }
            let stakeContract = getStakeContract()
            let preSaleContract = getPreSaleContract()

            const data = await appPublicClient().multicall({
                contracts: [
                    // @ts-ignore
                    {
                        ...stakeContract,
                        functionName: 'getRewards',   //我的质押奖励
                        args: [myAddress],
                    },
                    // @ts-ignore
                    {
                        ...stakeContract,
                        functionName: 'poolStakers',   //我的质押信息
                        args: [myAddress],
                    },
                    // @ts-ignore
                    {
                        ...stakeContract,
                        functionName: 'rewardTokensPerBlock',  //每个区块奖励
                    },
                    // @ts-ignore
                    {
                        ...stakeContract,
                        functionName: 'tokensStaked',   //总质押Token 数量
                    },
                    // @ts-ignore
                    {
                        ...stakeContract,
                        functionName: 'tokensStakedByPresale',  //预售已质押Token 数量
                    },
                    // @ts-ignore
                    {
                        ...stakeContract,
                        functionName: 'stakeToken',   //质押代币地址
                    },
                    // @ts-ignore
                    {
                        ...stakeContract,
                        functionName: 'lockTime',   //锁定时间
                    },
                    // @ts-ignore
                    {
                        ...stakeContract,
                        functionName: 'endBlock',   //结束区块
                    },
                    // @ts-ignore
                    {
                        ...stakeContract,
                        functionName: 'lastRewardedBlock',  //最新开始获取奖励区块
                    },
                    // @ts-ignore
                    {
                        ...preSaleContract,
                        functionName: 'endTime',
                    },
                    // @ts-ignore
                    {
                        ...preSaleContract,
                        functionName: 'totalTokensSold',
                    },
                ]
            })

            let resultData = {
                getRewards: data[0].result,           //[0]
                poolStakers: data[1].result,          //[1]
                rewardTokensPerBlock: data[2].result, //[2]
                tokensStaked: data[3].result,         //[3]
                tokensStakedByPresale: data[4].result,//[4]
                stakeToken: data[5].result,           //[5]
                lockTime: data[6].result,            //[6]
                endBlock: data[7].result,              //[7]
                lastRewardedBlock: data[8].result,     //[8]
                endTime: data[9].result,               //[9]
                totalTokensSold: data[10].result      //[10]
            }


            let tgbBalance = await fetchBalance({
                // @ts-ignore
                address: stakeContract.address,
                // @ts-ignore
                token: tgbAddress,
            })

            let myTgbBalance = await fetchBalance({
                // @ts-ignore
                address: myAddress,
                // @ts-ignore
                token: tgbAddress,
            })

            //console.log(`$tgb staking token $${tgbBalance.symbol} \n decemal:  ${tgbBalance.decimals} \n balance: ${tgbBalance.value} `)
            //console.log(`my  $tgb token $${myTgbBalance.symbol} \n decemal:  ${myTgbBalance.decimals} \n balance: ${myTgbBalance.value} `)

            //我的 TGB 余额 
            // @ts-ignore
            let myBalance = formatUnits(myTgbBalance.value, myTgbBalance.decimals)
            //我的质押值
            setStakeAmount(parseInt(myBalance))
            //当前质押总量
            // @ts-ignore
            let totalStake = formatUnits(resultData.tokensStaked, tgbBalance.decimals)

            // 总出售
            // @ts-ignore
            let totalTokensSold = Number(resultData.totalTokensSold)

            //当前质押池剩余TGB总量
            // @ts-ignore
            let totalBalance = formatUnits(tgbBalance.value, tgbBalance.decimals)
            //console.log(`$tgb totalBalance ${totalBalance}`)
            let endTime = Number(resultData.endTime)

            //剩余质押天数
            let RemainingStakeDays = (Math.floor(endTime * 1000 - new Date().getTime()) / 1000 / 3600 / 24)

            //console.log(`$tgb endTime : ${endTime} currentTime:${new Date().getTime() / 1000}  RemainingStakeDays: ${RemainingStakeDays}`)
            const block = await appPublicClient().getBlockNumber()

            // @ts-ignore
            let remainingBlock = resultData.endBlock - block
           // console.log(`end block : ${resultData.endBlock} currentBlock :${block} remainingBlock: ${remainingBlock}`)

            // @ts-ignore
            // let remainingBlock = (resultData.lastRewardedBlock ? resultData.endBlock - resultData.lastRewardedBlock : 0n)
            // @ts-ignore
            let recordPerBlock = formatUnits(resultData.rewardTokensPerBlock, tgbBalance.decimals)
            let remainingRecord = Number(recordPerBlock) * Number(remainingBlock)

            //当年年化收益率
            // @ts-ignore
            let apy = (((remainingRecord) / totalStake * (365 / RemainingStakeDays)) * 100).toFixed(1)
            console.log(`stake detail $tgb APY :${apy},  remainingRecord:${remainingRecord}  remainingBlock：${remainingBlock} recordPerBlock:${recordPerBlock}  totalBalance: ${totalBalance}, totalStake:${totalStake} RemainingStakeDays: ${RemainingStakeDays}`)

            // @ts-ignore
            apy = (apy && (apy !== 'Infinity' || apy !== 'NAN')) ? (apy + '%') : ''
            //占质押总额$tgb 百分比
            // @ts-ignore
            let stateRateStr = (totalStake / totalTokensSold * 100).toFixed(4)
            //console.log(`$tgb totalStake: ${totalStake}  totalTokensSold: ${totalTokensSold} stateRateStr: ${stateRateStr}`)

            //已支付的总奖励
            // @ts-ignore
            let totalReward = (2e8 - remainingRecord).toFixed(0)
            //console.log(` stake rate ${stateRateStr} totalStake:${totalStake}, totalBalance:${totalBalance}  remainingRecord:${remainingRecord} totalReward:${totalReward}`)

            // @ts-ignore
            let myStakeAmount = formatUnits(resultData.poolStakers[0], tgbBalance.decimals)
            // @ts-ignore
            let myStakeHarvestedRewards = formatUnits(resultData.poolStakers[3], tgbBalance.decimals)
            // @ts-ignore
            let myStakeRewardDebt = formatUnits(resultData.poolStakers[4], tgbBalance.decimals)
            // @ts-ignore
            let myGetReward = formatUnits(resultData.getRewards, tgbBalance.decimals)
            // @ts-ignore
            let myStateRateStr = (myStakeAmount / totalStake * 100).toFixed(2) + '%'
            setUnStakeAmount(parseInt(myStakeAmount))
            setMyRewardAmount(parseInt(myGetReward))
            // console.log(`my stake info => myStakeAmount:${myStakeAmount} myStakeHarvestedRewards: ${myStakeHarvestedRewards} myStakeRewardDebt : ${myStakeRewardDebt}`)


            let info = {
                stakeTokenAddress: resultData.stakeToken,
                endTime: endTime,
                apy: apy,
                stateRateStr: stateRateStr,
                totalReward: parseInt(totalReward).toLocaleString(),
                myStakeAmount: parseInt(myStakeAmount),
                myStakeHarvestedRewards: parseInt(myStakeHarvestedRewards),
                myStakeRewardDebt: parseInt(myStakeRewardDebt),
                myStateRateStr: myStateRateStr,
                myGetReward: parseInt(myGetReward),
                myBalance: parseInt(myBalance),
                totalStake: parseInt(totalStake).toLocaleString()
            }
            setInfoData(info)
            //console.log(info)
        } catch (error) {
            // console.log(`stakeInfo error${error}`)
            throw error
        }
    }
    useEffect(() => {
       // console.log('request homeInfo')
        stakeInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const maxMyStakeBalance = () => {
        if(infoData.myBalance) {
            setStakeAmount(infoData.myBalance)
        }
    }
    const maxMyUnStakeBalance = () => {
        if(infoData.myStakeAmount) {
            setUnStakeAmount(infoData.myStakeAmount)
        }
    }

    return (
        <>
             {contextHolder}
            <div className="container1">
                <div className="pledgeDetail">
                    <div className="pledgeDetailLogo">{t('blockTitle.zhiya')}</div>
                    <div className="desc">{t('pledgeDetail.text1')}</div>
                    <Row gutter={17}>
                        <Col xs={12} sm={12} lg={6}>
                            <div className="dataContainer" style={{ background: "#C5AC79", border: "1px solid #EFD8AA" }}>
                                <div className="dataTitle" style={{ color: "#181A20" }}>
                                    {t('pledgeDetail.text2')}
                                </div>
                                <div className="dataValue" style={{ color: "#181A20" }}>
                                    {infoData.apy}
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                            <div className="dataContainer">
                                <div className="dataTitle">
                                    {t('pledgeDetail.text3')}
                                </div>
                                <div className="dataValue">
                                    {infoData.totalStake}
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                            <div className="dataContainer">
                                <div className="dataTitle">
                                    {t('pledgeDetail.text4')}
                                </div>
                                <div className="dataValue">
                                    {infoData.stateRateStr}%
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} lg={6}>
                            <div className="dataContainer">
                                <div className="dataTitle">
                                    {t('pledgeDetail.text5')}
                                </div>
                                <div className="dataValue">
                                    {infoData.totalReward}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="my-pledge">
                        {
                            !connected ? <div className="bg">
                                <div className="btn" onClick={connectWithWalletConnect}>{t('pledgeDetail.text6')}</div>
                            </div> : ""
                        }
                        {
                            (connected && processing) ? <div className="bg2">
                                <div className="btn">{t('tip.text22')}</div>
                            </div> : ""
                        }
                        <div className="actionPledgeTitle">{t('pledgeDetail.text7')}</div>
                        <div className="minePledge">
                            <div className="minePledgeItem">
                                <p className="actionPledgeDesc">{t('pledgeDetail.text8')}</p>
                                <Input value={infoData.myStakeAmount} type="number" disabled={true} />
                            </div>
                            <div className="minePledgeItem">
                                <p className="actionPledgeDesc">{t('pledgeDetail.text9')}</p>
                                <Input value={infoData.myStateRateStr} disabled={true} />
                            </div>
                            <div className="minePledgeItem">
                                <p className="actionPledgeDesc">{t('pledgeDetail.text10')}</p>
                                <Input value={infoData.myStakeHarvestedRewards} type="number" disabled={true} />
                            </div>
                            <div className="minePledgeItem">
                                <p className="actionPledgeDesc">{t('pledgeDetail.text11')}</p>
                                <div style={{ position: "relative" }}>
                                    <Input value={myRewardAmount} type="number" disabled={true} />
                                    <div className="receive" onClick={getMyStakeReward}>{t('pledgeDetail.text12')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="actionPledge">
                            <div className="goPledge">
                                <div className="actionPledgeTitle">{t('pledgeDetail.text13')}</div>
                                <div className="actionPledgeDesc">{t('pledgeDetail.text14')}</div>
                                <div style={{ position: "relative" }}>
                                    <Input value={stakeAmount} type="number" autoComplete='off' />
                                    <div className="maxVal" onClick={maxMyStakeBalance}>{t('pledgeDetail.text15')}</div>
                                </div>
                                <div className="goPledgeBtn">
                                    <div className="learnMore">{t('pledgeDetail.text16')}</div>
                                    <div className="actionBtn" onClick={stakeToken}>{t('pledgeDetail.text13')}</div>
                                </div>
                            </div>
                            <div className="outPledge">
                                <div className="actionPledgeTitle">{t('pledgeDetail.text17')}</div>
                                <div className="actionPledgeDesc">{t('pledgeDetail.text19')}</div>
                                <div style={{ position: "relative" }}>
                                    <Input value={unStakeAmount} type="number" autoComplete='off' />
                                    <div className="maxVal" onClick={maxMyUnStakeBalance}>{t('pledgeDetail.text15')}</div>
                                </div>
                                <div className="goPledgeBtn">
                                    <div className="learnMore">{t('pledgeDetail.text16')}</div>
                                    <div className="actionBtn" onClick={unStakeToken}>{t('pledgeDetail.text18')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pledgeDetailAllBit">{t("blockTitle.zhiyazr")} $TGB</div>
                    <div style={{ width: "100%" }}>
                        <PledgeDetailArea />
                    </div>
                </div>
            </div>
        </>)
}

