
import { addEvent } from "../helper/countlyUtil"

const urls = {

    //审计
    auditUrl: 'https://www.cyberscope.io/audits/1-tgb',

    //twitter
    twitter: 'https://twitter.com/TGBetOfficial',

    //tg 频道
    tgbTgUrl: 'https://telegram.me/TGBetOfficialBot',

    //game tg 频道
    gameTgUrl: 'http://telegram.me/TGBetOfficialBot',

    //证书
    licenseUrl: 'https://tg-bet.gitbook.io/casino-license/',

    //白皮书
    whitePaperUrl: 'https://tg-bet.gitbook.io/whitepaper/',

    //tg 下载
    tgDownloadUrl: 'https://telegram.org/'
}


const openUrl = (url, clickKey) => {
    if(['telegramChannel','gameTelegramChannel'].includes(clickKey)){
        const tgid = localStorage.getItem("tgid");
        if (tgid) {
            url = `${urls.tgbTgUrl}?start=${tgid}`;
        }
    }
    if (url) {
        window.open(url, '_blank')
    } else {

    }

    if (clickKey) {
        addEvent(clickKey, 1)
    } else {

    }
}

export { urls, openUrl } 