import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { urls, openUrl } from '../util/const/urlConst';
import { Countlykeys } from '../util/const/countlyKey';
import { Dropdown, Menu, Modal } from 'antd';
import '../assets/style/TopBar.scss'
export default () => {
    const { t, i18n } = useTranslation();
    const langList = [
        { title: 'English', key: 'en', icon: "img/lang/en.png" },
        { title: 'عربي', key: 'ar', icon: "img/lang/ar.png" },
        { title: 'български', key: 'bg', icon: "img/lang/bg.png" },
        { title: '中文', key: 'zh', icon: "img/lang/zh.png" },
        { title: '繁體中文', key: 'zhTW', icon: "img/lang/zh-TW.png" },
        { title: 'čeština', key: 'cz', icon: "img/lang/cz.png" },
        { title: 'Deutsch', key: 'de', icon: "img/lang/de.png" },
        { title: 'Ελλάδα', key: 'el', icon: "img/lang/el.png" },
        { title: 'español', key: 'es', icon: "img/lang/es.png" },
        { title: 'Français', key: 'fr', icon: "img/lang/fr.png" },
        { title: 'Magyar', key: 'hu', icon: "img/lang/hu.png" },
        { title: 'Indonesia', key: 'id', icon: "img/lang/id.png" },
        { title: 'Italiano', key: 'it', icon: "img/lang/it.png" },
        { title: '日本語', key: 'ja', icon: "img/lang/ja.png" },
        { title: '한국인', key: 'ko', icon: "img/lang/ko.png" },
        { title: 'Nederlands', key: 'nl', icon: "img/lang/nl.png" },
        { title: 'Polski', key: 'pl', icon: "img/lang/pl.png" },
        { title: 'Português', key: 'pt', icon: "img/lang/pt.png" },
        { title: 'Română', key: 'ro', icon: "img/lang/ro.png" },
        { title: 'Русский', key: 'ru', icon: "img/lang/ru.png" },
        { title: 'slovenský', key: 'sk', icon: "img/lang/sk.png" },
        { title: 'แบบไทย', key: 'th', icon: "img/lang/th.png" },
        { title: 'Türkçe', key: 'tr', icon: "img/lang/tr.png" },
        { title: 'Tiếng Việt', key: 'vi', icon: "img/lang/vi.png" }
    ]
    const dispatch = useDispatch();
    // const togglePledgeDetail = useSelector((state: any) => state.global.togglePledgeDetail);
    // const toggleHowToBuy = useSelector((state: any) => state.global.toggleHowToBuy);
    // const toggleLicensePage:any = useSelector((state:any) => state.global.toggleLicensePage);
    const [isModalOpen, setIsModalOpen] = useState(false)

    let localLang = localStorage.getItem('language') || 'en'


    let item: any = langList.find(e => e.key === localLang);

    const [currentlang, setCurrentlang] = useState(item)


    const menu = () => {
        return <Menu onClick={handleMenuClick}>
            {langList.map((item: any, index: number) => <Menu.Item key={index}>
                <img className="zhMenuImg" src={item.icon} /><span className="langMenu">{item.title}</span>
            </Menu.Item>
            )}
        </Menu>
    }
    const handleMenuClick = (e: any) => {
        if (e && e.key) {
            const langObj: any = langList[e.key]
            setCurrentlang(langObj)
            i18n.changeLanguage(langObj.key)

            if (langObj.key) {
                localStorage.setItem('language', langObj.key)
            }

        }
    }
    const handleClick = (sectionId: any) => {
        dispatch({ type: 'setToggleHowToBuy', data: false })
        dispatch({ type: 'setTogglePledgeDetail', data: false })
        dispatch({ type: 'setLicensePage', data: false })
        sessionStorage.setItem("togglePledgeDetail", "false");
        sessionStorage.setItem("toggleHowToBuy", "false");
        sessionStorage.setItem("toggleLicensePage", "false");
        setIsModalOpen(false);
        setTimeout(() => {
            goToBlock(sectionId);
        }, 0)

    }
    const goToBlock = (sectionId: any) => {
        const targetElement = document.getElementById(sectionId);
        const fixedElementHeight = window.innerWidth <= 1000 ? 80 : 104;

        if (targetElement) {
            const rect = targetElement.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + rect.top - fixedElementHeight,
                behavior: 'smooth',
            });
        }
    }
    const goPcHowToBuy = () => {
        dispatch({ type: 'setToggleHowToBuy', data: true })
        dispatch({ type: 'setTogglePledgeDetail', data: false })
        dispatch({ type: 'setLicensePage', data: false })
        sessionStorage.setItem("togglePledgeDetail", "false");
        sessionStorage.setItem("toggleHowToBuy", "true");
        sessionStorage.setItem("toggleLicensePage", "false");
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const goPclicense = () => {
        dispatch({ type: 'setToggleHowToBuy', data: false })
        dispatch({ type: 'setTogglePledgeDetail', data: false })
        dispatch({ type: 'setLicensePage', data: true })
        sessionStorage.setItem("togglePledgeDetail", "false");
        sessionStorage.setItem("toggleHowToBuy", "false");
        sessionStorage.setItem("toggleLicensePage", "true");
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const handleShowMenu = () => {
        setIsModalOpen(true);
    }
    const goPcPledge = () => {
        dispatch({ type: 'setToggleHowToBuy', data: false })
        dispatch({ type: 'setTogglePledgeDetail', data: true })
        dispatch({ type: 'setLicensePage', data: false })
        sessionStorage.setItem("togglePledgeDetail", "true");
        sessionStorage.setItem("toggleHowToBuy", "false");
        sessionStorage.setItem("toggleLicensePage", "false");
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const goMobilePledge = () => {
        goPcPledge();
        setIsModalOpen(false)
    }

    const goAudit=()=>{
        openUrl(urls.auditUrl, Countlykeys.audit_click);
    }
    const goMobileHowToBuy = () => {
        dispatch({ type: 'setToggleHowToBuy', data: true })
        dispatch({ type: 'setTogglePledgeDetail', data: false })
        dispatch({ type: 'setLicensePage', data: false })
        setIsModalOpen(false)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const goMobilelicense = () => {
        dispatch({ type: 'setToggleHowToBuy', data: false })
        dispatch({ type: 'setTogglePledgeDetail', data: false })
        dispatch({ type: 'setLicensePage', data: true })
        setIsModalOpen(false)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <div className="navbar" id="navbar">
            <img alt="logo" className="topLogo" src="img/logo.png" />
            <div className="navContainer">
                <a className="menu-item" href="#home" onClick={() => { handleClick('home') }}>{t('topBar.index')}</a>
                <a className="menu-item" href="#preSale" onClick={() => { handleClick('preSale') }}>{t('topBar.welfare')}</a>
                <a className="menu-item" href="#operationContainer" onClick={() => { handleClick('operationContainer') }}>{t('topBar.methods')}</a>
                <a className="menu-item" href="#economicToken" onClick={() => { handleClick('economicToken') }}>{t('topBar.economic')}</a>
                <a className="menu-item" href="#pledgeCoin" onClick={() => { handleClick('pledgeCoin') }}>{t('topBar.reward')}</a>
                <a className="menu-item" href="#howToBuy" onClick={goPcHowToBuy}>{t('topBar.howBuy')}</a>
                <a className="menu-item" href="#license" onClick={goPclicense}>{t('topBar.license')}</a>
                <a className="menu-item" href="#commonProblemContainer" onClick={() => { handleClick('commonProblemContainer') }}>{t('topBar.qa')}</a>
            </div>
            <img alt="menu" className="menuImg" src="img/menu.png" />
            <div className="menuImg" onClick={handleShowMenu}></div>
            <div className="actionContainer">
                <div className="pledge" onClick={goPcPledge}>
                    {t('topBar.pledgeBtn')}
                </div>
                <div className="audit" onClick={goAudit}>
                    {t('topBar.auditBtn')}
                </div>

            </div>
            <Dropdown overlay={menu}>
                <div className="menuLink el-dropdown">
                    <img className="zhImg" src={currentlang.icon} alt='zhImg'/>
                    <span className="langMenu">{currentlang.title}</span>
                </div>
            </Dropdown>
            {isModalOpen ? <div className="mobileMenu">
                <Modal width="100%" visible={isModalOpen} footer={null} style={{ height: "100%" }} closable={false} className="menuDialog">
                    <div className="mobileNavContainer">
                        <a onClick={() => { handleClick('home') }}>{t('topBar.index')}</a>
                        <a onClick={() => { handleClick('preSale') }}>{t('topBar.welfare')}</a>
                        <a onClick={() => { handleClick('operationContainer') }}>{t('topBar.methods')}</a>
                        <a onClick={() => { handleClick('pledgeCoin') }}>{t('topBar.reward')}</a>
                        <a onClick={goMobileHowToBuy}>{t('topBar.howBuy')}</a>
                        <a onClick={goMobilelicense}>{t('topBar.license')}</a>
                        <a onClick={() => { handleClick('commonProblemContainer') }}>{t('topBar.qa')}</a>
                        <div className="pledge" onClick={goMobilePledge}>{t('topBar.pledgeBtn')}</div>
                        <div className="audit" onClick={goAudit}>{t('topBar.auditBtn')}</div>
                        {/* <Dropdown overlay={menu} placement="top">
                            <div className="menuLink el-dropdown">
                                <img className="zhImg" src={currentlang.icon} />
                                <span className="langMenu">{currentlang.title}</span>
                            </div>
                        </Dropdown> */}
                        <img onClick={() => { setIsModalOpen(false) }} alt="close logo" style={{ width: "24px", height: "24px" }}
                            src="img/close.png" />
                    </div>
                </Modal>

            </div> : ""}
        </div>
    )
}
